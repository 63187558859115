import { For, Show } from "solid-js";
import { SimilarBrandCardData } from "~/server/types/brand";
import { BrandCard } from "../brand/brand_card";

type SimilarBrandsProps = {
  similarBrands: SimilarBrandCardData[];
};

export function SimilarBrands(props: SimilarBrandsProps) {
  const similarBrandsWithThumbnail = props.similarBrands.filter(
    (it) => it.brandThumbnailUrl
  );

  return (
    <div class="flex flex-col gap-3">
      <h2 class="text-h5 text-textDark lg:text-h4"> Similar gift cards </h2>
      <div
        class="grid w-full grid-cols-3 content-between gap-x-2 gap-y-4 overflow-hidden lg:grid-cols-4 lg:grid-rows-1"
        style={{ "align-content": "space-between" }}
        classList={{
          "grid-rows-2": similarBrandsWithThumbnail.length > 3,
          "grid-rows-1": similarBrandsWithThumbnail.length <= 3,
        }}
      >
        <For each={similarBrandsWithThumbnail}>
          {(similarBrand) => (
            // Although all the brands have thumbnails, we will
            // still have this safeguard.
            // In fact, it is necessary in our sandbox, where
            // quite a few brands don't have thumbnails
            <Show when={similarBrand.brandThumbnailUrl}>
              <BrandCard
                name={similarBrand.brandTitle}
                categoryName={similarBrand.brandCategory}
                imageUrl={similarBrand.brandThumbnailUrl}
                discountPercentage={similarBrand.discountPercentage}
                brandKey={similarBrand.brandKey}
                class="w-[106px] lg:w-[124px]"
              ></BrandCard>
            </Show>
          )}
        </For>
      </div>
    </div>
  );
}
