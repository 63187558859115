import rehypeRaw from "rehype-raw";
import { For, Show } from "solid-js";
import SolidMarkdown from "solid-markdown";
import { FootNote } from "~/server/types/brand";
import { rehypeUppercaseATags } from "~/utils/common";

type BrandL2FooterNotesProps = {
  footerList: FootNote[];
};

export default function BrandL2FooterNotes(props: BrandL2FooterNotesProps) {
  return (
    <Show when={props.footerList.length > 0}>
      {/* max-width is fixed same as [brandkey].tsx  */}
      <div class="w-full max-w-[573px] px-4 lg:max-w-[1011px]">
        <div class="py-12">
          <div class="h-[1px] w-full bg-baseTertiaryDark "></div>
        </div>

        <For each={props.footerList}>
          {(footerNote) => (
            <div>
              <h2 class="text-h4 text-textDark">
                <SolidMarkdown
                  class="markdown"
                  rehypePlugins={[rehypeRaw] as any}
                >
                  {footerNote.title}
                </SolidMarkdown>
              </h2>
              <div class="py-2 text-normal text-textNormal ">
                <SolidMarkdown
                  class="markdown"
                  rehypePlugins={[rehypeUppercaseATags, rehypeRaw] as any}
                >
                  {footerNote.description}
                </SolidMarkdown>
              </div>
            </div>
          )}
        </For>
      </div>
    </Show>
  );
}
