import { Show } from "solid-js";

type HeaderProps = {
  brandName: string;
  discountPercentage: number;
  showShoppingVoucher: boolean;
  showPercOff: boolean;
};

export function BrandTitle(props: HeaderProps) {
  return (
    <div class="w-full">
      <Show
        when={props.discountPercentage > 0}
        fallback={
          <h1>
            <span class="font-['Inter']  text-h3 text-textDark lg:text-h2">
              {props.brandName} card{" "}
            </span>
          </h1>
        }
      >
        <h1>
          <span class="font-['Inter']  text-h3 text-textDark lg:text-h2">
            {props.brandName} gift card{" "}
            {props.showShoppingVoucher ? "(shopping voucher)" : ""}
            <Show when={props.showPercOff}>at</Show>
          </span>
          <Show when={props.showPercOff}>
            <span class="font-['Inter'] text-h3 text-successDark lg:text-h2">
              {" "}
              {props.discountPercentage}% off
            </span>
          </Show>
        </h1>
      </Show>
    </div>
  );
}
