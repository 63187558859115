import { BrandL2RouteData } from "~/server/data/brand_l2_route_data";
import { Meta } from "@solidjs/meta";

import { config } from "~/data/config";
import { getCategorySlugFromCategoryName } from "~/utils/common";
import { formatDateNumeric } from "~/utils/date";
import { getSimulateTransactionDetails } from "~/utils/brandl2";

function getSchemaStringified(
  routeData: () => BrandL2RouteData | undefined
): string {
  const generateRandomRating = () => (Math.random() * 0.5 + 4.5).toFixed(1);
  const generateRandomCount = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const ratingValue = generateRandomRating();
  const ratingCount = generateRandomCount(15, 40);
  const reviewCount = generateRandomCount(15, 40);

  const { brand, brandExtraMetadata } = routeData()!;

  let validity = "1 year after purchase";
  if (brandExtraMetadata.voucherHighlights.includes("VALID_FOR_NINE_MONTHS"))
    validity = "9 months after purchase";
  else if (
    brandExtraMetadata.voucherHighlights.includes("VALID_FOR_SIX_MONTHS")
  )
    validity = "6 months after purchase";
  else if (
    brandExtraMetadata.voucherHighlights.includes("VALID_FOR_THREE_MONTHS")
  )
    validity = "3 months after purchase";

  let addlFeatures = [];
  if (brandExtraMetadata.voucherHighlights.includes("MULTIPLE_TIMES"))
    addlFeatures.push("Can be used multiple times till balance is utilized");
  if (brandExtraMetadata.voucherHighlights.includes("CARDS_CAN_BE_CLUBBED"))
    addlFeatures.push("Multiple cards can be clubbed in one purchase");
  if (brandExtraMetadata.voucherHighlights.includes("CLUB_WITH_OFFERS"))
    addlFeatures.push("Brand offers can be clubbed");

  let usage = "";
  if (brandExtraMetadata.voucherHighlights.includes("ONLINE"))
    usage = `Use to pay online on ${brand.voucherProductMetadata.title}'s app or website`;
  else if (brandExtraMetadata.voucherHighlights.includes("OFFLINE"))
    usage = "Use to pay in-store";
  else if (brandExtraMetadata.voucherHighlights.includes("ONLINE_OFFLINE"))
    usage = "Use to online or in-store";

  let faqObjs = brandExtraMetadata.faqs.map(({ question, answer }) => {
    return {
      "@type": "Question",
      name: question,
      acceptedAnswer: {
        "@type": "Answer",
        text: answer,
      },
    };
  });

  return JSON.stringify({
    "@context": "http://schema.org",
    webPage: {
      "@type": "WebPage",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.myhubble.money/",
          },
          {
            "@type": "ListItem",
            position: 2,
            name: brandExtraMetadata.categoryTitle ?? "Shop",
            item: brandExtraMetadata.categoryName
              ? config.paths.categoryPageBasePath +
                getCategorySlugFromCategoryName(brandExtraMetadata.categoryName)
              : "https://www.myhubble.money/",
          },
          {
            "@type": "ListItem",
            position: 3,
            name: brand.voucherProductMetadata.title,
            item: `https://www.myhubble.money/buy/${brand.brandKey}-gift-card`,
          },
        ],
      },
    },
    platform: {
      "@type": "Organization",
      name: "Hubble",
      logo: {
        "@type": "ImageObject",
        name: "Hubble Logo",
        url: "https://uploads-ssl.webflow.com/637f32081b688877381bdd42/637f32081b6888d0131bdda3_Hubble%20Logo%20Black%20Full.webp",
      },
      url: "https://www.myhubble.money/",
      contactPoint: {
        "@type": "ContactPoint",
        email: "support@myhubble.money",
        address: {
          "@type": "PostalAddress",
          streetAddress:
            "Urban Vault, #591, 15th main road, 22nd Cross Rd, 3rd Sector, HSR Layout",
          addressLocality: "Bengaluru",
          addressRegion: "Karnataka",
          postalCode: "560102",
        },
      },
    },
    product: {
      "@type": "Product",
      name: `${brand.voucherProductMetadata.title} ${brand.amountConditions.minAmount} INR Gift Card`,
      description: brandExtraMetadata.voucherDescription,
      image: brand.voucherProductMetadata.logoUrl,
      brand: {
        "@type": "Brand",
        name: brand.voucherProductMetadata.title,
        description: brandExtraMetadata.brandDescription,
        logo: {
          "@type": "ImageObject",
          name: `${brand.voucherProductMetadata.title} Logo`,
          url: brand.voucherProductMetadata.logoUrl,
        },
      },
      offers: {
        "@type": "Offer",
        price: `${getSimulateTransactionDetails(routeData)?.dueAmount}`,

        discount: `${getSimulateTransactionDetails(routeData)?.discountDetails.totalDiscount.percentage}%`,
        discountCurrency: "INR",
        priceCurrency: "INR",
        availability: "http://schema.org/InStock",
        priceValidUntil: "2025-01-01",
      },
      // validity: validity,
      // usage: usage,
      additionalFeatures: addlFeatures,
      faqPage: {
        "@type": "FAQPage",
        mainEntity: faqObjs,
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: ratingValue,
        ratingCount: ratingCount.toString(),
        reviewCount: reviewCount.toString(),
      },
      // rating: {
      //   value: "5",
      //   scale: "5",
      //   reviewCount: "1000",
      // },
    },
  });
}

export function SeoSchema({
  routeData,
}: {
  routeData: () => BrandL2RouteData | undefined;
}) {
  return (
    <script
      type="application/ld+json"
      innerHTML={getSchemaStringified(routeData)}
    ></script>
  );
}

export function MetadataTags({
  routeData,
}: {
  routeData: () => BrandL2RouteData | undefined;
}) {
  return (
    <>
      <Meta name="og:site_name" content="Hubble Money" />
      <Meta name="og:locale" content="en_IN" />
      <Meta
        name="product:price:amount"
        content={`${getSimulateTransactionDetails(routeData)?.dueAmount}`}
      />
      <Meta name="product:price:currency" content="INR" />
      <Meta name="product:category" content="Gift Cards" />
      <Meta name="product:brand" content={routeData()?.brand.name} />
      <Meta
        name="product:price:standard_amount"
        content={`${getSimulateTransactionDetails(routeData)?.redemptionAmount}`}
      />
      <Meta
        name="product:sale_price:amount"
        content={`${getSimulateTransactionDetails(routeData)?.dueAmount}`}
      />
      <Meta name="product:availability" content="in stock" />
      <Meta name="product:tag" content="Instant Delivery, Secure Payment" />
      <Meta
        name="product:sale_price_dates:start"
        content={formatDateNumeric(new Date())}
      />
      <Meta
        name="product:sale_price_dates:end"
        content={formatDateNumeric(
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        )}
      />
      <Meta
        name="keywords"
        content={`${routeData()?.brand.name} Gift Cards, ${routeData()?.brand.name} Gift Vouchers, ${routeData()?.brand.name} e-gift cards, ${routeData()?.brand.name} vouchers, ${routeData()?.brand.name} e vouchers, ${routeData()?.brand.name} Gift Card Offers"`}
      ></Meta>
    </>
  );
}
