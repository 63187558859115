export function WhatsappTip() {
  return (
    <div class="inline-flex h-10 w-full items-center justify-start gap-2">
      <img
        class="h-10"
        src="https://gullak-assets.s3.ap-south-1.amazonaws.com/icons/image%20658.png"
        alt="Whatsapp icon"
      />
      <div class="text-textDark shrink grow basis-0 font-['Inter'] text-medium">
        The gift card will be ⚡️instantly delivered here and to your WhatsApp
        as well
      </div>
    </div>
  );
}
