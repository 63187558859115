import { animate } from "motion";
import { onMount } from "solid-js";
import { hubbleAssured } from "~/assets/assets";
import { HubbleAssuredDetails } from "~/components/brand/assured_details";
import { useModal } from "~/components/modal";

type HubbleAssuredProps = {};

export function HubbleAssured(props: HubbleAssuredProps) {
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  onMount(() => {
    const element = document.querySelector(".anim-assured-logo")!;
    animate(
      element,
      {
        opacity: [0, 0.5, 1],
        scale: [0, 1.5, 1],
      },
      { duration: 1, easing: "ease-in-out" }
    );
  });

  return (
    <div
      class="h-fit w-full cursor-pointer rounded-2xl border border-neutral-200 p-1 shadow-sm"
      onClick={() => {
        updateModalContentGetter(() => {
          return <HubbleAssuredDetails />;
        });

        setIsModalOn(true);
      }}
    >
      <div
        class="inline-flex w-full items-end justify-between rounded-xl border border-gray-100 bg-gradient-to-r from-slate-200 to-white px-[22px] py-[12.5px]"
        style="background: linear-gradient(90deg, #DBEAEF 1.79%, #FFF 55.81%);"
      >
        <div>
          <div class="flex flex-row gap-0.5">
            <img
              class="anim-assured-logo h-6 w-6"
              src={hubbleAssured}
              alt="Hubble assured guarantee"
            />
            <span class="text-h5 text-successDark">Hubble assured</span>
          </div>
          <div class="text-medium text-baseSecondaryMedium">
            100% money back guaranteed
          </div>
        </div>
        <div class="text-smallBold uppercase text-basePrimaryDark underline">
          LEARN MORE
        </div>
      </div>
    </div>
  );
}
